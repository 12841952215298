<template>
  <div class="wrapper">
    <article class="image">
      <img src="@/assets/img/impacther.jpeg" alt="ImpactHER">
    </article>
    <article class="forms">
      <top-navbar />

      <auth-content />
    </article>
  </div>
</template>
<style lang="scss" scoped>
    .wrapper{
        display: flex;
        article{
            display: inline-flex;
            // height: 100vh;
            width: 50%;
            &.image{
                align-items: center;
                justify-content: center;
                background: #fefffd;
                img{
                    width: 100%;
                }
            }
            &.forms{
                flex-direction: column;
                justify-content: center;
                border-left: 1px solid #ccc;
            }
        }
    }
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import AuthContent from "./Content.vue";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    AuthContent,
  },
  methods: {
  }
};
</script>
