<template>
  <div class="col-xl-10 col-lg-7 col-md-6">
    <card class="card" title="Send Outbound SMS">
      <div>
        <form @submit.prevent>
          <div class="row">
            <div class="col-10">
              <card class="card" title="Please paste your csv phone numbers here.">
                <div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Phone Numbers</label>
                        <textarea
                          rows="4"
                          class="form-control border-input"
                          placeholder="+2348137572021,+2348037390286"
                          v-model="phoneNumbers"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <card class="card" title="Please Enter your SMS message here.">
                <div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Message</label>
                        <textarea
                          rows="4"
                          class="form-control border-input"
                          placeholder="Welcome to ImpactHER"
                          v-model="message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="text-center">
            <p-button type="success"
                      round
                      :loading="isSignupInvite"
                      @click.native.prevent="submitForm">
              Send SMS
            </p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </card>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      counsellor: {},
      phoneNumbers: null,
      message: null,
      sendSMSError: null,
    };
  },
  async mounted() {
    this.counsellor = getCounsellor();
  },
  computed: {
    ...mapState('auth', [
      'isSendSMS'
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'sendSMS'
    ]),
    closeAlert() {this.sendSMSError = null},
    async submitForm() {
      try {
        const params = {
          workspaceId: this.counsellor.workspaceId,
          phoneNumbers: this.phoneNumbers,
          senderId: "ImpactHER",
          message: this.message,
        };
        this.sendSMS(params);
        this.phoneNumbers = "";
        this.message = "";
        swal({
          title: "Successfully Sent SMS",
          text: "Success",
          buttons: {
            cancel: true,
            confirm: true,
          }
        })
      } catch (ex) {
        this.sendSMSError = ex;
        console.log(`error, ${ex}`);
      }
    },
  }
};

</script>
<style lang="scss">
.backBtn{
  margin-bottom: 10px;
}
</style>
