import invoke from './invoke';

export const fetchSmsLogsData = async (options) => {
  try {
    const res = await invoke('POST', `/sms/logs`, options);
    return res;
  } catch (ex) {
    throw ex;
  }
};
