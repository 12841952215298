import { fetchData } from '@/utils/api/analytics';

const defaultState = {
  callCount: null,
  isFetchingCallCount: false,

  callDurationBar: null,
  isFetchingCallDurationBar: false,

  callDuration: null,
  isFetchingCallDuration: false,

  pieCalls: null,
  isFetchingPieCalls: false,

  pieCallsDuration: null,
  isFetchingPieCallsDuration: false,

  pieStateTag: null,
  isFetchingPieStateTag: false,

  pieSMS: null,
  isFetchingPieSMS: false,

  pieSMSSent: null,
  isFetchingPieSMSSent: false,

  calls: null,
  isFetchingCalls: false,

  barCalls: null,
  isFetchingBarCalls: false,
};

const mutations = {
  setIsFetchingCallCount(state, status) {
    state.isFetchingCallCount = status;
  },
  setCallCount(state, callCount) {
    state.callCount = callCount;
  },

  setIsFetchingCallDurationBar(state, status) {
    state.isFetchingCallDurationBar = status;
  },
  setCallDurationBar(state, callDuration) {
    state.callDurationBar = callDuration;
  },

  setIsFetchingCallDuration(state, status) {
    state.isFetchingCallDuration = status;
  },
  setCallDuration(state, callDuration) {
    state.callDuration = callDuration;
  },

  setIsFetchingPieCalls(state, status) {
    state.isFetchingPieCalls = status;
  },
  setPieCalls(state, pieCalls) {
    state.pieCalls = pieCalls;
  },

  setIsFetchingPieCallsDuration(state, status) {
    state.isFetchingPieCallsDuration = status;
  },
  setPieCallsDuration(state, pieCalls) {
    state.pieCallsDuration = pieCalls;
  },

  setIsFetchingPieStateTag(state, status) {
    state.isFetchingPieStateTag = status;
  },
  setPieStateTag(state, pieStateTag) {
    state.pieStateTag = pieStateTag;
  },

  setIsFetchingPieSMS(state, status) {
    state.isFetchingPieSMS = status;
  },
  setPieSMS(state, pieSMS) {
    state.pieSMS = pieSMS;
  },

  setIsFetchingPieSMSSent(state, status) {
    state.isFetchingPieSMSSent = status;
  },
  setPieSMSSent(state, pieSMSSent) {
    state.pieSMSSent = pieSMSSent;
  },

  setIsFetchingCalls(state, status) {
    state.isFetchingCalls = status;
  },
  setCalls(state, calls) {
    state.calls = calls;
  },

  setIsFetchingBarCalls(state, status) {
    state.isFetchingBarCalls = status;
  },
  setBarCalls(state, barCalls) {
    state.barCalls = barCalls;
  },

};

const actions = {
  async fetchCallCount({ commit }, data) {
    try {
      commit('setIsFetchingCallCount', true);
      const results = await fetchData(data);
      commit('setCallCount', results);
      commit('setIsFetchingCallCount', false);
    } catch (ex) {
      commit('setIsFetchingCallCount', false);
      throw ex;
    }
  },

  async fetchCallDurationBar({ commit }, data) {
    try {
      commit('setIsFetchingCallDurationBar', true);
      const results = await fetchData(data);
      commit('setCallDurationBar', results);
      commit('setIsFetchingCallDurationBar', false);
    } catch (ex) {
      commit('setIsFetchingCallDurationBar', false);
      throw ex;
    }
  },

  async fetchCallDuration({ commit }, data) {
    try {
      commit('setIsFetchingCallDuration', true);
      const results = await fetchData(data);
      commit('setCallDuration', results);
      commit('setIsFetchingCallDuration', false);
    } catch (ex) {
      commit('setIsFetchingCallDuration', false);
      throw ex;
    }
  },

  async fetchPieCalls({ commit }, data) {
    try {
      commit('setIsFetchingPieCalls', true);
      const results = await fetchData(data);
      commit('setPieCalls', results);
      commit('setIsFetchingPieCalls', false);
    } catch (ex) {
      commit('setIsFetchingPieCalls', false);
      throw ex;
    }
  },

  async fetchPieCallsDuration({ commit }, data) {
    try {
      commit('setIsFetchingPieCallsDuration', true);
      const results = await fetchData(data);
      commit('setPieCallsDuration', results);
      commit('setIsFetchingPieCallsDuration', false);
    } catch (ex) {
      commit('setIsFetchingPieCallsDuration', false);
      throw ex;
    }
  },

  async fetchPieStateTag({ commit }, data) {
    try {
      commit('setIsFetchingPieStateTag', true);
      const results = await fetchData(data);
      commit('setPieStateTag', results);
      commit('setIsFetchingPieStateTag', false);
    } catch (ex) {
      commit('setIsFetchingPieStateTag', false);
      throw ex;
    }
  },

  async fetchCalls({ commit }, data) {
    try {
      commit('setIsFetchingCalls', true);
      const results = await fetchData(data);
      commit('setCalls', results);
      commit('setIsFetchingCalls', false);
    } catch (ex) {
      commit('setIsFetchingCalls', false);
      throw ex;
    }
  },

  async fetchPieSMS({ commit }, data) {
    try {
      commit('setIsFetchingPieSMS', true);
      const results = await fetchData(data);
      commit('setPieSMS', results);
      commit('setIsFetchingPieSMS', false);
    } catch (ex) {
      commit('setIsFetchingPieSMS', false);
      throw ex;
    }
  },

  async fetchPieSMSSent({ commit }, data) {
    try {
      commit('setIsFetchingPieSMSSent', true);
      const results = await fetchData(data);
      commit('setPieSMSSent', results);
      commit('setIsFetchingPieSMSSent', false);
    } catch (ex) {
      commit('setIsFetchingPieSMSSent', false);
      throw ex;
    }
  },

  async fetchBarCalls({ commit }, data) {
    try {
      commit('setIsFetchingBarCalls', true);
      const results = await fetchData(data);
      commit('setBarCalls', results);
      commit('setIsFetchingBarCalls', false);
    } catch (ex) {
      commit('setIsFetchingBarCalls', false);
      throw ex;
    }
  },

};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
