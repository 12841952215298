<template>
  <div class="row">
    <router-view></router-view>
  </div>
</template>
<script>
import SmsList from "./Sms/List.vue";
export default {
  components: {
    SmsList,
  }
};
</script>
<style lang="scss" scoped>
</style>
