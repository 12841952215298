<template>
  <div class="col-12">
    <div class="row">
      <div class="col-md-3">
        <fg-input type="text"
                  placeholder="Search Logs"
                  label="Search"
                  v-model="search">
        </fg-input>
      </div>
      <div class="col-6 side-actions">
        <div class="dropdown">
          <!-- <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Export
          </button> -->
          <div class="dropdown-menu dropdown-menu-left p4">
            <form class="col-12" @submit.prevent>
              <div class="form-group">
                <label>From</label>
                <date-picker name="startDate" v-model="filter.startDate" :config="config"></date-picker>
              </div>
              <div class="form-group">
                <label>To</label>
                <date-picker name="endDate" v-model="filter.endDate" :config="config"></date-picker>
              </div>
              <p-button type="success"
                        style="margin-left: auto; display: block;"
                        :loading="isDownloadingLogs"
                        round
                        @click.native.prevent="downloadWithFilter">
                Download
              </p-button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <card title="Sms Logs" subTitle="Sms Logs breakdown">
      <div slot="pagination">
        <a class="btn btn-just-icon btn-outline-default btn-sm" v-on:click="fetchWithFilter">
          <i class="ti-reload"></i>
        </a>
        &nbsp;
        &nbsp;
        &nbsp;
        page {{filter.page}} of {{(filter.page * filter.count)}}
        &nbsp;
        <a class="btn btn-just-icon btn-outline-default btn-sm" :disabled="filteredLogs.page <= 0" v-on:click="prevPage">
          <i class="ti-angle-left"></i>
        </a>
        &nbsp;
        <a class="btn btn-just-icon btn-outline-default btn-sm" :disabled="filteredLogs.length !== filter.count" v-on:click="nextPage">
          <i class="ti-angle-right"></i>
        </a>
      </div>
      <div slot="raw-content" class="table-responsive">
        <!-- <paper-table :data="logs" :columns="tableColumns" :loading="isFetchingLogs" /> -->
        <table class="table">
          <thead>
          <th>Phone Number</th>
          <th>Created</th>
          <th>Sender Id</th>
          <th>Message ID</th>
          <th>Send Status</th>
          <th>Delivered Status</th>
          <th>Network Code</th>
          <th>Message Parts</th>
          <th>Failure Reason</th>
          <th>Updated</th>
          </thead>
          <tbody>
          <tr v-if="isFetchingLogs">
            <td colspan="8" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
          </tr>
          <tr v-else-if="filteredLogs.length === 0">
            <td colspan="8" style="text-align: center;">No Sms Logs Found</td>
          </tr>
          <tr v-else v-for="(item, idx) in filteredLogs" :key="idx">
            <slot :row="item">
              <td>{{item.phoneNumber}}</td>
              <td>{{item.created | formatDate}}</td>
              <td>{{item.senderId}}</td>
              <td>{{item.messageId}}</td>
              <td>{{item.sendStatus}}</td>
              <td>{{item.deliveredStatus}}</td>
              <td>{{item.networkCode}}</td>
              <td>{{item.messageParts}}</td>
              <td>{{item.failureReason}}</td>
              <td>{{item.updatedTime | formatDate}}</td>
            </slot>
          </tr>
          </tbody>
        </table>
      </div>
    </card>
  </div>
</template>
<script>
import moment from 'moment';
import {
  mapState,
  mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {

  data() {
    return {
      counsellor: null,
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      search: null,
      filteredLogs: [],
      filter: {
        // startDate: new Date(),
        // endDate: new Date(),
        workspaceId: null,
        page: 1,
        count: 50,
      },
    };
  },
  computed: {
    ...mapState('sms', [
      'logs', 'isFetchingLogs', 'isDownloadingLogs'
    ]),
  },
  watch: {
    search(newValue){
      if (newValue && newValue !== '') {
        this.filteredLogs = this.logs.filter(report=> {
          return report.phoneNumber.toLowerCase().includes(newValue) || report.messageId.toLowerCase().includes(newValue)
            || report.networkCode.toLowerCase().includes(newValue) || report.deliveredStatus.toLowerCase().includes(newValue)
            || report.failureReason.toLowerCase().includes(newValue)
        });
      } else {
        this.filteredLogs = this.logs;
      }
    },
    logs(newValue) {
      console.log({logs:this.logs})
      this.filteredLogs = newValue;
    }
  },
  filters: {
    formatDate(date) {
      let result = 'N/A';
      if (date) result = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return result;
    }
  },
  async created() {
    this.counsellor = getCounsellor();
    this.filter.workspaceId = this.counsellor.workspaceId;
    await this.fetchWithFilter();
  },
  methods: {
    ...mapActions('sms', [
      'fetchSmsLogs'
    ]),
    prevPage() {
      if (this.filter.page > 0) {
        this.filter.page -= 1;
        this.fetchWithFilter();
      }
    },

    nextPage() {
      if (this.logs.length === this.filter.count) {
        this.filter.page += 1;
        this.fetchWithFilter();
      }
    },
    async fetchWithFilter() {
      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
      await this.fetchSmsLogs(params);
    },
    downloadWithFilter() {
      const params = {...this.filter};
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
      params.startDate = new Date(params.startDate).getTime();
      params.endDate = new Date(params.endDate).getTime();
      console.log(params)
      this.downloadCallLogs(params);
    }
  }
};
</script>
<style lang="scss" scoped>
.caller-number{
  color: #68B3C8;
  cursor: pointer;
  &:hover{
    color: #3091B2;
  }
}
.side-actions{
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  flex-direction: row-reverse;
  vertical-align: baseline;
  .dropdown{
    margin-bottom: 15px;
  }
}
.dropdown-menu{
  background-color: #fff !important;
  padding: 15px 0px !important;
  width: 300px;
}
</style>
