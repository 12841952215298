import { fetchSmsLogsData } from '@/utils/api/sms';

const defaultState = {
  logs: [],
  isFetchingLogs: false,
  isDownloadingLogs: false,
};

const mutations = {
  setIsFetchingLogs(state, status) {
    state.isFetchingLogs = status;
  },
  setIsDownloadingLogs(state, status) {
    state.isDownloadingLogs = status;
  },
  setLogs(state, logs) {
    state.logs = logs;
  },
};

const actions = {
  async fetchSmsLogs({ commit }, data) {
    try {
      commit('setIsFetchingLogs', true);
      const results = await fetchSmsLogsData(data);
      commit('setLogs', results.entries);
      commit('setIsFetchingLogs', false);
    } catch (ex) {
      commit('setIsFetchingLogs', false);
      throw ex;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
