<template>
  <div class="col-xl-10 col-lg-7 col-md-6">
    <p-button type="info"
              class="backBtn"
              round
              @click.native.prevent="$router.push('/settings')">
      Back
    </p-button>
    <card class="card" title="Change Your Password">
      <div>
        <form @submit.prevent>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="password"
                        label="Old Password"
                        required
                        placeholder="e.g mysecretpassword"
                        v-model="oldPassword">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="password"
                        label="New Password"
                        required
                        placeholder="e.g mysecretpassword"
                        v-model="newPassword">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input type="password"
                        label="Retype New Password"
                        required
                        placeholder="e.g mysecretpassword"
                        v-model="newPassword2">
              </fg-input>
            </div>
          </div>
          <div class="text-center">
            <p-button type="success"
                      round
                      :loading="isChangePassword"
                      @click.native.prevent="submitForm">
              Submit
            </p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </card>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      counsellor: {},
      oldPassword: null,
      newPassword: null,
      newPassword2: null,
      changePasswordError: null,
    };
  },
  async mounted() {
    this.counsellor = getCounsellor();
  },
  computed: {
    ...mapState('auth', [
      'isChangePassword'
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'changePassword'
    ]),
    closeAlert() {this.changePasswordError = null},
    async submitForm() {
      try {
        const params = {
          workspaceId: this.counsellor.workspaceId,
          counsellorId: Number(this.counsellor.counsellorId),
          oldPassword: this.oldPassword,
          newPassword: this.newPassword2
        };
        if (this.newPassword !== this.newPassword2) {
          this.changePasswordError = 'Passwords do not match';
          this.$notify({
            message: 'Passwords do not match',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
          return;
        }
        await this.changePassword(params);
        this.$notify({
          message: 'Team member invited successfully',
          icon: "ti-check-box",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
        await this.$router.push('/settings')
      } catch (ex) {
        this.changePasswordError = ex;
        console.log(`error, ${ex}`);
        this.$notify({
          message: 'Error updating password',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    },
  }
};
</script>
<style lang="scss">
.backBtn{
  margin-bottom: 10px;
}
</style>
